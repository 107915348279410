
import Swiper from 'swiper';

export default class{

	constructor(){
        const getFeed = document.querySelector('[data-get-feed]')
        if(getFeed){
            const url = getFeed.getAttribute('data-url')
            fetch( url , {})
            .then(res => res.text())
            .then(async data => {
                if(getFeed){
                    getFeed.innerHTML = data

                    this.caseSlider()
                }
            })
        }    
    }

    caseSlider(){
        new Swiper ('#case--slider',{
            loop: false,
            // easing:"linear",
            loopAdditionalSlides : 5,
            speed:400,
            slidesPerView: 1.2,
            spaceBetween: 20,
            simulateTouch:true,
            centeredSlides: false,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            breakpoints: {
                481: {
                    slidesPerView: 1.5,
                    spaceBetween:  20,
                },
                576: {
                    slidesPerView: 2.2,
                    spaceBetween:  20,
                },
                992: {
                    slidesPerView: 3.2,
                    spaceBetween:  30,
                },
            }
        })
    }
    
    
}