
import Swiper from 'swiper';

export default class{

	constructor(){

        // barba.hooks.beforeOnce((data) => {
        //     this.heroSlider()
        //     this.caseSlider()
        //     // this.blogSlider()
        //     // this.pickupSlider()
        //     // this.eventSlider()

        // })
        // barba.hooks.after((data) => {
        //     this.heroSlider()
        //     this.caseSlider()
        //     // this.blogSlider()
        //     // this.pickupSlider()
        //     // this.eventSlider()
        // });
        
        this.heroSlider()
    }
    
    heroSlider(){
        new Swiper ('#hero--slider',{
            loop: true,
            effect :'fade',
            speed:3000,
            allowTouchMove :false,
            simulateTouch:false,
            autoplay: {
                delay: 4000
            },
        })
    }

}