
import MicroModal from 'micromodal';

export default class{

	constructor(){
        this.drawerLinks = []
        this.opened = false

        this.opening_class = 'drawer-opening'
        this.opened_class = 'drawer-opened'
        this.closing_class = 'drawer-closing'
        
        this.hamburger_id = "js-hamburger"
        this.drawer_id = "js-drawer"
        this.drawer_link_class = "[data-drawer-links]"
        this.drawer_str_class = "l-drawer__nav--str"

        // barba.hooks.once((data) => {
        // })

        this.init()
     
    }

    init(){
        this.body = document.body;
        this.hamburger = document.querySelector('#' +this.hamburger_id);
        this.drawer = document.querySelector('#' + this.drawer_id);
        if(this.drawer){
            this.drawerLinks = this.drawer.querySelectorAll(this.drawer_link_class);
            if(this.hamburger){
                this.hamburger.addEventListener('click',(e) => {
                    this.drawerClick(e);
                })
            }
            if(this.drawerLinks){
                if( this.drawerLinks.length > 0 ){
                    this.drawerLinks.forEach( (drawerLink) => {
                        this.drawerRemoveClass(drawerLink)
                    });
                }
            }
            
        }
        
    }
    drawerClick(e){
            
        this.drawerToggleClass();
    }
    drawerToggleClass(){
        if(!this.drawer.classList.contains('is-open')){
            this.open();
        }else{
            this.close();
        }
    }
    drawerRemoveClass(drawerLink){
        drawerLink.addEventListener('click',() =>{
            this.close();
        })
    } 
    open(){



        MicroModal.show(this.drawer_id,{
            onShow (){
                document.querySelector("#js-drawer").scrollTop = 0
                setTimeout(() => {
                    document.querySelector("body").classList.add('-drawer-opened')
                    
                }, 400);
            }, // [1]
            onClose (){

                document.querySelector('#js-hamburger').setAttribute('aria-expanded',false)
            }, 
            // openTrigger: 'data-custom-open', // [3]
            // closeTrigger: 'data-custom-close', // [4]
            // openClass: 'is-open', // [5]
            disableScroll: true, // [6]
            disableFocus: true, // [7]
            awaitOpenAnimation: true, // [8]
            awaitCloseAnimation: true, // [9]
        })
        this.hamburger.setAttribute('aria-expanded',true)
        // anime({
        //     targets:this.drawer_str_class,
        //     translateY:['100%',0],
        //     easing: 'easeInOutSine',
        //     duration:500,
        //     delay:function(el,i){
        //         return i * 30;
        //     }
        // })
        // this.opened = true;

    }
    close (){
        // this.body.classList.remove(this.opening_class);
        // this.body.classList.add(this.closing_class);
        this.hamburger.setAttribute('aria-expanded',false)
        document.querySelector("body").classList.remove('-drawer-opened')
        // setTimeout(() =>{
        //     this.drawer.scrollTop =  0;
        //     this.body.classList.remove(this.closing_class);
        //     this.body.classList.remove(this.opened_class);
        //     this.body.classList.remove(this.opening_class);
        //     this.opened = false;
        // },300)
        MicroModal.close(this.drawer_id)
    }
}